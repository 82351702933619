.scroll-to-top{
    width: fit-content;
    position: fixed;
    bottom: 60px;
    right: 50px;
    z-index: 100;
    animation: bounce2 2s ease infinite;
}
@keyframes bounce2 {
	0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
	40% {transform: translateY(-15px);}
	60% {transform: translateY(-7.5px);}
}
.scroll-to-top .scroll-to-top-btn{
    width: 50px !important;
    height: 50px !important;
    border-radius: 50%;
    background-color: var(--primary-color);
    color:white;
    border: 1px solid var(--light-gray);
}
.scroll-to-top .scroll-to-top-btn:hover{
    background-color: white;
    color: var(--primary-color);
}