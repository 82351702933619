
.user-dashboard-nav .dashboard-nav{
    background-color: var(--primary-color);
    padding:10px;
}
.user-dashboard-nav .menu-wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.user-dashboard-nav .dashboard-nav .menu-wrapper .left-side{
    flex-basis: 50%;
    display: flex;
    align-items: center;
    padding: 0 10px;
}
.user-dashboard-nav .dashboard-nav .logo-content{
    background-color: white;
    margin: 0 10px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    width: 70px;
    height: 70px;
    overflow: hidden;
}
.user-dashboard-nav .left-side .dashboard-search-box{
    flex-basis:80%;
}
.user-dashboard-nav .dashboard-search-box .form-group{
    position: relative;
}
.user-dashboard-nav .dashboard-search-box .form-group input{
    padding: 10px !important;
}
.user-dashboard-nav .dashboard-search-box .form-group .search-btn{
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    background: transparent;
    border: none;
    color: var(--primary-color);
}
.user-dashboard-nav .show-custom-dropdown-1{
    height: 250px !important;
    width: 200px;
}
.user-dashboard-nav .show-custom-dropdown-2{
    height: 250px !important;
    width: 200px;
}
.user-dashboard-nav .dashboard-nav .right-side{
    flex-basis: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.user-dashboard-nav .notifications .custom-dropdown-content-wrapper {
    top:41px;
    width: 250px;
    overflow-y: auto;
}
.user-dashboard-nav .user-list .custom-dropdown-content-wrapper {
    top:41px;
    width: 200px;
}
.user-dashboard-nav .notifications .show-custom-dropdown-1{
    height: 250px !important;
    width: 250px;
}
.user-dashboard-nav .custom-dropdown-content{
    padding: 0;
}
.list-menu li{
    padding:8px 10px;
    background-color:white;
    color:var(--custom-gray);
    font-size:16px;
    font-weight:normal;
    position: relative;
}
 .list-menu li.page-active{
    background-color: var(--primary-color);
}
 .list-menu li.page-active a{
    color: white;
}
 .list-menu li.page-active svg{
    color: white;
}
 .list-menu li a{
    color: var(--custom-gray);
}
 .list-menu li:hover{
    background-color: var(--primary-color);
    
}
 .list-menu li:hover a{
    color: white;
}
 .list-menu .user-list li svg{
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    margin-left: 10px;
}   

.user-dashboard-nav .nav-btn .user-list .custom-dropdown-btn{
    border: none;
    padding: 5px 10px;
    width: 100%;
    background-color: transparent;
    text-align: left;
    color: white;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    align-items: center;
}
.user-dashboard-nav .nav-btn .user-list .custom-dropdown-btn .avatar{
    width:40px;
    height:40px;
    margin: 0 3px;
    border-radius: 50%;
    overflow: hidden;
}
.user-dashboard-nav .nav-btn .user-list .custom-dropdown-btn .avatar img{
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;
}
.user-dashboard-nav .mobile-menu-wrapper{
    position: fixed;
    right: -100%;
    width: 100%;
    height: 100%;
    top: 0;
    background: white;
    padding: 20px;
    z-index: 100;
    text-align: start;
}
.user-dashboard-nav .mobile-menu-wrapper .notifications{
    color: black;
}
.user-dashboard-nav .mobile-menu-wrapper .user-list{
    color: black;
}
.user-dashboard-nav .mobile-menu-wrapper.show{
    right:0 !important;
}
.mobile-menu-wrapper .notification-menu li{
    display: flex;
    align-items: center;
    padding:8px;
    background-color:white;
    -webkit-transition: all 300ms;
   -moz-transition: all 300ms;
     -o-transition: all 300ms;
        transition: all 300ms;
        cursor: pointer;
}
.mobile-menu-wrapper .notification-menu li:hover{
   background-color: #eee;
}
.mobile-menu-wrapper .notification-menu li .notification-icon{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color:var(--custom-green);
    width: 50px;
    height: 50px;
    border-radius: 50%;
}
.mobile-menu-wrapper .notification-menu li .notification-content h5{
    font-size: 13px;
}
.mobile-menu-wrapper .notification-menu li .notification-content p{
    margin: 0;
    padding: 0;
    font-size: 10px;
}
.mobile-menu-wrapper .user-list li{
    text-align: start !important;
}
.no-nots{
    width: 100%;
    height: 100%;
    padding: 10px;
    color: gray;
    font-size: 18px;
    text-align: center;
    line-height: 10;
}
.user-menu-open{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: white;
    color: var(--primary-color);
    line-height: 2.2;
}
.mobile-menu-wrapper-close{
    width: 40px;
    height: 40px;
    color: white;
    background: var(--primary-color);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
/********************************************************************/
@media (max-width:576px) {
    .user-dashboard-nav .dashboard-nav .left-side{
        flex-basis:85% !important;
    }
    .user-dashboard-nav .dashboard-nav .right-side{
        flex-basis:15% !important;
    }
    .user-dashboard-nav .dashboard-nav .logo-content{
        width: 45px;
        height: 45px;
    }
}