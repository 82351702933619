/*start home sidebar style*/
.home-sidebar{
    overflow: hidden;
    position: fixed;
    top: 0;
    width: 0px;
    height: 100vh;
    z-index: 2000 !important;
    background:#0000009c;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
}
.home-sidebar.home-sidebar-ar{
    right: 0;
}
.home-sidebar.home-sidebar-en{
    left: 0;
}
.home-sidebar .sidebar-content{
    width: 250px;
    background-color: var(--white-color);
    height: 100%;
}
.home-sidebar.active{width:100% !important;}
.home-sidebar .author-img img{max-width:100%;width: 100%; height: 100%;}
.home-sidebar .author-bio .author__title{font-size:18px;color: black;font-weight: 600;}
.home-sidebar a{color: black;}
.home-sidebar .author-content{border-bottom: 2px solid var(--primary-color); padding:15px 10px;display: flex; align-items:center;}
.home-sidebar .sidebar-menu a{font-size:14px; font-weight: 600;}
.home-sidebar .setting-content{
    font-size:14px;
    font-weight: bold;
    position:absolute;
    bottom:0;
    cursor: pointer;
    background:white;
}
.author-img{
    width: 50px;
      height: 50px;
      border-radius: 50%;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 5px;
      cursor: pointer;
}
/*End home sidebar style*/