@import url(./css/normalize.min.css);
@import url(./css/style.css);
@import url(./css/custom.css);
*{margin: 0;padding: 0;color: unset;box-sizing: border-box;text-decoration: none;outline: none;}
:root{
--primary-color: #f16022;
--secondary-color:#0f2f50;
--white-color:#fff;
--light-primary:#28548075;
--primary-dark-color:#0a0494;
--secondary-2-color:#2a2c2f;
--facebook-color:#3b5999;
--twitter-color:#55acee;
--pinterest-color:#bd081c;
--skype-color:#00aff0;
--linkedin-color:#0077b5;
--instagram-color:#d91cac;
--google-plus-color:#dd4b39;
--youtube-color:#cc181e;
--telegram-color:#179cde;
--whatsapp-color:#25d366;
}
body { font-family: "Cairo", sans-serif !important;margin: 0;padding: 0;overflow-x: hidden;font-size: 16px;}
ul {list-style: none;padding: 0 !important;}
a {text-decoration: none !important;outline: none;color: inherit;}
section{position: relative;}
input[type="password"]{user-select: none;}
p,h1,h2,h3,h4,h5,span,a,ul,li,input
{font-family: "Cairo", sans-serif !important;}
