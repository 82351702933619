@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css);
    
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,400i,700,700i&display=swap);
    
@import url(https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,400;1,700&display=swap);

.bg-overlay {
  background-color: rgba(27, 33, 39, 0.5);
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
}
.bg__overlay-black {
  position: relative;
  z-index: 1;
}
.bg__overlay-black::before {
  background: rgba(0, 16, 34, 0.5);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}
/*End overlay*/
/*start logo text*/
.logo-text {
  font-size: 30px;
  text-transform: capitalize;
  color: var(--primary-color);
  cursor: pointer;
}
/*End logo text*/
.section {
  min-height: 100vh;
}
section{
  padding-top: 50px;
    padding-bottom: 50px;
    margin-top: 25px;
    margin-bottom: 25px;
  
}
/*Start custom checkbox*/
.checkmark-wrap {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 20px 0 0;
}
.custom-checkbox {
  font-size: 14px;
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  margin-right: 20px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkmark {
  position: absolute;
  top: 50%;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  transform: translateY(-50%);
  border-radius: 2px;
}
.custom-checkbox:hover input ~ .checkmark {
  background-color: var(--primary-color);
}
.custom-checkbox input:checked ~ .checkmark {
  background-color: var(--primary-color);
}
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.custom-checkbox input:checked ~ .checkmark:after {
  display: block;
}
.custom-checkbox .checkmark:after {
  left: 8px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
/*End custom checkbox*/
/*Start Rang slider style*/
.MuiSlider-track,
.MuiSlider-rail,
.MuiSlider-thumb {
  background: var(--primary-color);
  color: var(--primary-color);
}
.css-eg0mwd-MuiSlider-thumb:hover {
  box-shadow: 0px 0px 0px 8px #fc6f2ed6 !important;
}
.price-range-slider .value-0,
.price-range-slider .value-1 {
  color: var(--primary-color);
}
.price-range-slider .parent-span {
  font-size: 18px !important;
  font-weight: bold;
}
/*bg style*/
.bg-theme-overlay:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #000;
  opacity: 0.3;
}
.bg-theme-overlay {
  position: relative;
  background-image: url(../images/bg.jpg);
  width: 100%;
  background-position: center 50%;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  -moz-background-size: cover;
  position: relative;
}
@media screen and (min-width: 320px) and (max-width: 575px) {
  .bg-theme-overlay {
  background-attachment: fixed;
}
}@media screen and (min-width: 576px) and (max-width: 768px) {
  .bg-theme-overlay {
  background-attachment: fixed;
}
}
/*bg style*/