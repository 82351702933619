
.footer-area h1 {
    color: #000;
    text-align: center;
    text-transform: uppercase;
}
.footer-area p {
    font-size: 16px;
    margin-bottom: 1.5em;
    color: #000;
}
.wrapper__footer {
    padding:50px 0;
    position: relative;
    text-align: start;
    background-color: var(--secondary-color);
}
.wrapper__footer .widget__footer {
    display: block;
}
.wrapper__footer .widget__footer .footer-title {
    text-transform: capitalize;
    margin-bottom: 15px;
    color: #fff;
    font-size: 18px;
}
.wrapper__footer .widget__footer p {
    font-size: 14px;
    line-height: 1.7;
    margin-bottom: 0;
    color: #adb5bd;
}
.wrapper__footer .widget__footer ul li {
    display: flex;
    margin-top: 5px;
    font-size: 14px;
}
.wrapper__footer .widget__footer ul li b {
    color: #ced4da;
    margin-right: 5px;
    font-size: 18px;
}
.wrapper__footer .widget__footer ul li span {
    color: #ced4da;
}
.wrapper__footer .widget__footer .link__category-two-column ul {
    -moz-columns: 2;
    columns: 2;
    -moz-column-gap: 20px;
    column-gap: 20px;
    margin-bottom: 0;
}
.wrapper__footer .widget__footer .link__category-two-column ul li {
    margin: 0;
    padding: 0;
    line-height: normal;
    -moz-column-break-inside: avoid;
    break-inside: avoid;
    display: block;
}
.wrapper__footer .widget__footer .link__category-two-column ul li a {
    text-transform: capitalize;
    font-size: 14px;
    line-height: 2;
    color: #adb5bd;
    transition: 0.3s ease;
}
.wrapper__footer .widget__footer .link__category-two-column ul li a:hover {
    color:var(--primary-color);
    text-decoration: none;
}
.wrapper__footer .widget__footer .link__category {
    position: relative;
    padding: 15px 0;
}
.wrapper__footer .widget__footer .link__category ul {
    -moz-columns: 3;
    columns: 3;
    -moz-column-gap: 20px;
    column-gap: 20px;
    margin-bottom: 0;
}
.wrapper__footer .widget__footer .link__category ul li {
    margin: 0;
    padding: 0;
    line-height: normal;
    -moz-column-break-inside: avoid;
    break-inside: avoid;
    display: block;
}
.wrapper__footer .widget__footer .link__category ul li a {
    text-transform: capitalize;
    font-size: 14px;
    line-height: 2;
    color: #adb5bd;
    transition: 0.3s ease;
}
.wrapper__footer figure{
    background: white;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}
.wrapper__footer .logo-footer {
    width: 70px;
    height: 70px;
    position: relative;
}
.wrapper__footer figure.image-logo {
    width: 175px;
}
.wrapper__footer figure.image-logo img {
    position: relative;
    position: relative;
    width: 100%;
    max-width: 100%;
    height: auto;
    -o-object-fit: cover;
    object-fit: cover;
}
.wrapper__footer .social__media {
    text-align: right;
}
.wrapper__footer .social__media .list-inline {
    margin-bottom: 0;
}
.wrapper__footer .social__media .list-inline span {
    text-transform: capitalize;
    font-size: 11px;
    font-weight: 700;
    margin-right: 10px;
}

.bg__footer-bottom-v1, .bg__footer-bottom {
    background-color: #21242d;
    padding: 15px 0;
    border-top: 1px solid #2c2f37;
}
.bg__footer-bottom-v1 span, .bg__footer-bottom span {
    color: #ced4da;
    font-size: 12px;
}
.bg__footer-bottom-v1 span a, .bg__footer-bottom span a {
    color: var(--primary-color);
    text-transform: uppercase;
}
.bg__footer-bottom-v1 .list-inline, .bg__footer-bottom .list-inline {
    margin-bottom: 0;
    text-align: right;
}
.bg__footer-bottom-v1 .list-inline .list-inline-item a, .bg__footer-bottom .list-inline .list-inline-item a {
    color: #ced4da;
    font-size: 12px;
    text-transform: capitalize;
    transition: 0.3s ease;
}
.bg__footer-bottom-v1 .list-inline .list-inline-item a:hover,
.bg__footer-bottom .list-inline .list-inline-item a:hover,
.wrapper__footer .widget__footer .link__category ul li a:hover
 {
    color: var(--primary-color);
    text-decoration: none;
}
.bg__footer-bottom-v1 {
    background-color: #111111;
    border-top: 1px solid #212121;
}
.wrapper__footer .download-app-wrapper img{
    width:150px;
    margin: 5px;
}
/*media query*/
@media screen and (min-width: 320px) and (max-width: 575px) {
    .wrapper__footer .social__media .list-inline span {
    display: block;
    margin-bottom: 10px;
}
.bg__footer-bottom-v1 .list-inline, .bg__footer-bottom .list-inline {
    text-align: center;
    margin-bottom: 5px;
}
.bg__footer-bottom-v1 span, .bg__footer-bottom span {
    text-align: center;
    display: block;
}
.wrapper__footer .social__media .list-inline {
    margin-bottom: 15px;
}
.wrapper__footer .social__media {
    text-align: left;
}
.footer-area {
    padding-top: 30px;
}
.footer-area h1 {
    font-size: 26px;
}
.wrapper__footer {
    padding: 40px 0;
}
.wrapper__footer .widget__footer .footer-title {
    font-size: 16px;
    margin-top: 15px;
}
.wrapper__footer .widget__footer .link__category ul li a {
    line-height: 30px;
}
}

@media screen and (min-width: 576px) and (max-width: 768px) {
    .wrapper__footer .widget__footer .link__category-two-column ul {
    -moz-columns: 2;
    columns: 2;
    -moz-column-gap: 30px;
    column-gap: 30px;
}
.wrapper__footer .widget__footer .link__category ul {
    -moz-columns: 2;
    columns: 2;
    -moz-column-gap: 30px;
    column-gap: 30px;
}
}