.internal-navbar{
  padding:0 !important;
  min-height: 65px;
}
.navbar-soft {
  box-shadow: 0 2px 3px rgba(100, 100, 100, 0.1);
  font-size: 14px;
}
.navbar-soft .navbar-brand {
  color: #111;
  font-weight: 600;
  font-size: 18px;
}
.navbar-soft .navbar-nav .nav-item {
  margin-left: 10px;
  margin-right: 10px;
}

.navbar-soft .navbar-nav .nav-item:hover .nav-link,
.navbar-soft .navbar-nav .nav-item.show .nav-link,
.navbar-soft .navbar-nav .nav-item.homeActive .nav-link,
.navbar-soft .navbar-nav .nav-item.listsActive .nav-link,
.navbar-soft .navbar-nav .nav-item.newsActive .nav-link,
.navbar-soft .navbar-nav .nav-item.agencyActive .nav-link,
.navbar-soft .navbar-nav .nav-item.agentsActive .nav-link,
.navbar-soft .navbar-nav .nav-item.aboutUsActive .nav-link,
.navbar-soft .navbar-nav .nav-item.contactUsActive .nav-link,
.navbar-soft .navbar-nav .nav-item.projectsActive .nav-link{
  border-bottom: 3px solid var(--primary-color);
  color: #111;
}
.navbar-soft .dropdown-menu {
  border: 0;
  background-clip: initial;
  border-radius: 0;
  box-shadow: 0 1px 5px rgba(70, 70, 70, 0.2);
}
.navbar-soft .dropdown-menu .dropdown-item {
  padding: 0.6rem 1.5rem;
  border-bottom: 1px solid #f1f1f1;
  cursor: pointer;
  text-align: start;
}
.navbar-transparent {
  box-shadow: 0 2px 3px rgba(100, 100, 100, 0.1);
  background-color: transparent;
}
.navbar-soft {
  box-shadow: none;
  z-index: 4;
}
.navbar-soft .navbar-brand img {
  position: relative;
  -o-object-fit: cover;
  object-fit: cover;
  height: auto;
  width:70px;
}
.navbar-soft .navbar-nav .nav-item .nav-link {
  padding: 25px 10px;
  margin: 5px 0;
  font-size: 18px;
  font-weight:600;
  text-transform: capitalize;
  border-bottom: 3px solid transparent;
  color: var(--white-color);
  cursor: pointer;
}
.internal-navbar .nav-link {
  color: #000 !important;
}
.navbar-soft .navbar-nav .nav-item .dropdown-menu {
  padding-top: 0;
  padding-bottom: 0;
}
.navbar-soft .navbar-nav .nav-item .dropdown-menu li a {
  text-transform: capitalize;
  font-weight: 500;
}
.navbar-soft .navbar-nav .nav-item .dropdown-menu .col-megamenu {
  padding: 20px 0;
}
.fixed-top,
.navbar-custom {
  box-shadow: 0 2px 3px rgba(100, 100, 100, 0.1);
  background-color: #fff;
  -webkit-animation: smoothScroll 1s forwards;
  animation: smoothScroll 1s forwards;
}
.fixed-top .navbar-brand img,
.navbar-custom .navbar-brand img {
  display: none;
}
.fixed-top .navbar-brand img:last-child,
.navbar-custom .navbar-brand img:last-child {
  display: block;
  width: 152px;
}
.fixed-top .navbar-nav .nav-item .nav-link,
.navbar-custom .navbar-nav .nav-item .nav-link {
  color: #000;
}
.dropdown-toggle[data-toggle="dropdown"]:after {
  font-family: "FontAwesome";
  font-weight: 900;
  content: "\f107";
  border: 0;
  vertical-align: baseline;
}
.navbar .search {
  width: 24px;
  max-width: 100%;
}
.navbar .search a:hover {
  color: #000;
}
.navbar-hover .dropdown.has-dimmer:hover .nav-link {
  position: relative;
  z-index: 12;
}
.navbar-hover .dropdown.has-dimmer:hover:after {
  z-index: 10;
  opacity: 1;
  visibility: visible;
  position: fixed;
}
.navbar-hover.navbar-dark .nav-item:hover .nav-link {
  color: #fff;
}
.navbar-hover.navbar-light .nav-item:hover .nav-link {
  color: #000;
}
.navbar-hover .dropdown-menu:not(.animate) {
  display: none;
}
.navbar-hover .animate {
  display: block;
  opacity: 0;
  visibility: hidden;
  transition: 0.4s;
  margin-top: 0;
}
.navbar-hover .dropdown-menu .animate {
  transition: 0.1s;
}
.navbar-hover .fade-down {
  top: 90%;
  transform: rotateX(-80deg);
  transform-origin: 0% 0%;
}
.navbar-hover .fade-up {
  top: 100px;
}
.navbar-hover .dropdown-menu .fade-down {
  top: 0;
}
.navbar-hover .nav-item:hover > .dropdown-menu,
.navbar-hover .dropdown:hover > .dropdown-menu {
  display: block;
  transition: 0.4s;
  opacity: 1;
  visibility: visible;
  top: 100%;
  transform: rotateX(0deg);
}
.dropdown-menu > li:hover {
  background-color: #f1f1f1;
}
.dropdown-menu li {
  position: relative;
}
.dropdown-menu li.has-megasubmenu {
  position: static;
}
.dropdown-menu li:hover > .submenu,
.dropdown-menu li:hover > .dropdown-menu {
  display: block;
  transition: 0.4s;
  opacity: 1;
  visibility: visible;
  top: 0%;
  transform: rotateX(0deg);
}
.dropdown-menu li:hover > .megasubmenu {
  display: block;
  transition: 0.4s;
  opacity: 1;
  visibility: visible;
  top: 0%;
  transform: rotateX(0deg);
}
.dropdown-item:active {
  background-color: var(--primary-color) !important;
}

/*media query*/
@media screen and (min-width: 320px) and (max-width: 575px) {
  .navbar-soft .navbar-nav .nav-item .nav-link {
    padding: 1rem 0;
  }
  .navbar-soft {
    padding: 0.5rem 1rem;
  }
  .navbar-soft .navbar-brand img{
    width: 55px;
  }
}
@media screen and (min-width: 576px) and (max-width: 768px) {
  .navbar-soft .navbar-nav .nav-item .nav-link {
    padding: 1rem 0;
  }
}
@media all and (max-width: 991px) {
  .navbar-soft .navbar-nav {
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .navbar-soft .navbar-nav .nav-link {
    padding: 0.6rem 0rem;
  }
}
