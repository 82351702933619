.share-component{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2000;
    top: 0;
    left: 0;
    background:#000000b8;
    display: flex;
    align-items:center;
    justify-content: center;
}
.share-component .share-wrapper{
    border-radius:10px;
    text-align: start;
    padding: 10px;
    max-width: 300px;
}
.share-component .share-wrapper h3{
    font-size: 22px;
}
.share-component .share-wrapper button{
   margin:4px;
   transition: all 0.3s;
}
.share-component .share-wrapper button:hover{
   transform: scale(1.1);
}
.share-component .share-wrapper .copy-btn{
    width: 60px;
    height: 60px;
    border-radius:50%;
    border:none;
    background-color:var(--twitter-color);
    color:white;
    font-weight: bold;
}
