.nots-item{
    display: flex;
    align-items:center;
    padding: 5px;
    margin: 10px 0;
}
.nots-item .img-box{
    flex-shrink: 0;
    width:70px;
    height:70px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 7px 0 #c9d1d9;
    margin: 0 10px 0 5px;
}
.nots-item .img-box img{
    max-width: 100%;
    max-height: 100%;
}
@media (max-width:766px) {
    .nots-item .img-box{
        width: 50px;
        height: 50px;
    }
    .nots-item h5{
        font-size: 16px;
    }
    .nots-item p{
        font-size: 14px;
    }
}