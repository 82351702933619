.user-wishlist-page{
    display: flex;
    justify-content:flex-end;
}
.user-wishlist-page .user-dashboard{
    flex-basis:calc(100% - 300px);
    flex-shrink: 0;
}

.user-wishlist-page .dashboard-main-content{
    background-color: var(--section-bg);
}
.user-wishlist-page .dashboard-main-content .row-content h3{
    font-size: 18px;
}
.user-wishlist-page .dashboard-main-content .row-footer{
    padding: 10px;
}
.delete-from-wishlist{
    position: absolute;
    right:20px;
    top: 10px;
}
@media (max-width:992px) {
    .user-wishlist-page .user-dashboard{
        flex-basis:100%;
    }
}
