.profile__agent__body{
    padding: 20px;
}
.contact-btn{
    padding: 15px;
    color: white;
    border-radius: 5px;
    margin: 5px;
    text-transform: capitalize;
    width: 100%;
    display: block;
    font-weight: bold;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
}
.contact-btn:hover{
    background-color: white !important;
    color:black;
}
.contact-btn.whatsapp-btn{
    background: var(--whatsapp-color);
}
.contact-btn.phone-btn{
    background: var(--twitter-color);
}
.contact-btn.email-btn{
    background:var(--youtube-color);
}
.contact-btn.share-btn{
    background:var(--twitter-color);
    border:none;
}