.user-profile-sidebar-wrap{
    width:300px;
    max-width: 300px;
    overflow: hidden;
    position: fixed;
    height: 100vh;
    z-index: 1000;
    left: 0;
    top: 0;
}
.user-profile-sidebar {
    width: 100%;
}
.user-profile-sidebar .sidebar-nav{
    background-color: white;
    height: 100vh;
    text-align: start;
    padding: 10px;
    position: relative;
}
.user-profile-sidebar .sidebar-nav .author-content{
    padding:20px 10px;
    border-bottom: 1px solid #eee;
    text-align: start;
}
.user-profile-sidebar .sidebar-nav .author-content .author-img{
    height:60px;
    width: 60px;
    border-radius:50%;
    overflow: hidden;
    margin: 0 5px;
}
.user-profile-sidebar .sidebar-nav .author-content .author-img img{
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;
}
.user-profile-sidebar .sidebar-nav .author-content .author-bio .author__title{
   font-size: 18px;
}
.user-profile-sidebar .sidebar-nav .author-content .author-bio .author__meta{
    font-size: 15px;
    color: var(--custom-gray);
}
.profilePage,
.bookingPage,
.listPage,
.wishlistPage,
.settingPage,
.notificationsPage,
.addUnitPage,
.logout{
    background-color: var(--primary-color) !important;
}
.profilePage,
.profilePage svg,
.notificationsPage,
.notificationsPage svg,
.bookingPage,
.bookingPage svg,
.listPage,
.listPage svg,
.wishlistPage,
.wishlistPage svg,
.settingPage,
.settingPage svg,
.addUnitPage,
.addUnitPage svg,
.logout{
    color: white !important;
}
/****************************************************************/
.sidebar-menu-wrap{
    padding:10px;
}
.sidebar-menu .sidebar-menu-item{
    padding:8px 10px;
    background-color:white;
    color:var(--custom-gray);
    font-size:16px;
    text-transform: capitalize;
    text-align: start;
    font-weight:normal;
    margin:8px 0;
    border-radius: 5px;
    position: relative;
    cursor: pointer;
    display: flex;
    align-items:center;
    justify-content: start;
}
.sidebar-menu .sidebar-menu-item .page-active svg,
.sidebar-menu .sidebar-menu-item:hover{
    color: white;
}
.sidebar-menu .sidebar-menu-item:hover{
    background-color: var(--primary-color);
}
.sidebar-menu .sidebar-menu-item svg{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}   
/****************************************************************/