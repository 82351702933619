.single__detail-area {
    padding: 40px 0;
}
.single__detail-area-title h3,
.single__detail-price ,
.single__detail-area-title p,
.single__detail-desc{
    text-align: start;
}
.single__detail-area-title p {
    margin-bottom: 0;
}
.single__detail-area-price {
    text-align: end;
}
.single__detail-area-price .list-inline {
    margin: 0;
}
.single__detail-price .list-inline,
.single__detail-area-price ,
.single__detail-area-title,
.property__detail-info-list,
.widget__sidebar__body .list-unstyled li{
    position: relative;
}
.single__detail-desc p {
    line-height:2;
}
.detail-heading {
    margin: 20px 0 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #eaeaea;
}
.property__detail-info h6 {
    margin: 15px 0;
}
.property__detail-info-list li {
    margin-bottom: 8px;
    font-size: 18px;
}
.blog__grid {
    margin-top: 30px;
}
@media screen and (min-width: 320px) and (max-width: 575px) {
    .single__detail-price {
    text-align: left;
}
.single__detail-price h3 {
    font-size: 18px;
    float: left;
}
.single__detail-price .list-inline {
    text-align: right;
}
.single__detail-desc h5 {
    font-size: 16px;
}
.single__detail-title p {
    margin-bottom: 0;
    font-size: 14px;
}
.single__detail-title h3 {
    font-size: 18px;
}
.single__detail-area-price .list-inline {
    text-align: right;
}
.single__detail-area-price h3 {
    font-size: 18px;
    float: left;
}
.single__detail-area-price {
    text-align: start;
    margin-top: 15px;
}
.single__detail-area-title h3 {
    font-size: 18px;
}
.single__detail-features-review {
    margin-bottom: 30px;
}
}