
  /* .mySwiper .swiper-slide {
    width: 25%;
    height: 100%;
    opacity: 0.4;
  }
  .mySwiper .swiper-slide-thumb-active {
    opacity: 1;
  } */
  
  .swiper-button-prev.swiper-button-disabled,
  .swiper-button-next.swiper-button-disabled{
    pointer-events: unset !important;
  }