.read-more-less .show{
    height: auto !important;
}
.read-more-less .ph{
    position: relative;
    height:190px;
    overflow: hidden;
}
.read-more-less .show_btn{
    background: var(--primary-color);
    color: #fff;
    height: 46px;
    width: 200px;
    border-radius: 5px;
    z-index: 100;
    position: relative;
    border: 2px solid white;
}
.shadow-span{
    position: absolute;
    bottom: -15px;
    left: 0;
    right: 0;
    width: 100%;
    height: 20px;
    background: white;
    box-shadow: 0px -25px 30px 8px white;
}