.listing-sidebar{
    text-align: start;
}
.categories-list-item{cursor: pointer;}
/*download*/
.download {
    margin-bottom: 30px;
}
.download h5 {
    font-family: "Open Sans", serif;
    font-size: 18px;
    margin-bottom: 20px;
    text-align: start;
}
.download__item {
    background-color: transparent;
    position: relative;
    border: 1px solid #e9ecef;
    transition: 0.5s;
    padding: 15px 20px;
    margin: -1px 0 0;
}
.download__item a {
    font-weight: 500;
    display: block;
    color: #002247;
    font-size: 14px;
    text-decoration: none;
    text-transform: capitalize;
    font-family: "Open Sans", sans-serif;
}
.download__item i {
    color: #002247;
    transition: 0.5s;
    padding-right: 20px;
}
.download__item:hover {
    color: #fff;
    border-color: var(--primary-color);
    background-color: var(--primary-color);
}
.download .download__item:hover a, .download .download__item:hover i {
    color: #fff;
}
/*download*/