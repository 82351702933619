.contact-us-sidebar{text-align: start;}
.wrap__contact-form {
    display: block;
}
.wrap__contact-form h5 {
    font-size: 18px;
    text-transform: capitalize;
    margin-bottom: 30px;
}
@media screen and (min-width: 320px) and (max-width: 575px) {
    .wrap__contact-form h5 {
    margin-bottom: 25px;
}
}.wrap__contact-form .form-group label {
    font-weight: 600;
}
@media screen and (min-width: 320px) and (max-width: 575px) {
    .wrap__contact-form .form-group .btn-contact {
    margin-top: none;
}
}.wrap__contact-form .wrap__contact-form-office {
    display: block;
}
.wrap__contact-form .wrap__contact-form-office .list-unstyled li {
    font-size: 14px;
    display: flex;
    margin-bottom: 15px;
    font-weight: 500;
}
.wrap__contact-form .wrap__contact-form-office .list-unstyled li span {
    display: block;
    line-height: 35px;
}
.wrap__contact-form .wrap__contact-form-office .list-unstyled li span i.fa {
    float: left;
    border: 1px solid #dbe1e8;
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    font-size: 18px;
    margin-right: 15px;
}
.wrap__contact-form .wrap__contact-form-office .list-unstyled li span a {
    color: #000;
}
.wrap__contact-form .wrap__contact-form-office .list-unstyled li span a:hover {
    text-decoration: none;
}
.wrap__contact-form .wrap__contact-form-office .social__media .list-inline {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
.list-unstyled {
    padding-left: 0;
    list-style: none;
}
.list-inline {
    padding-left: 0;
    list-style: none;
}
.list-inline-item {
    display: inline-block;
}
.list-inline-item:not(:last-child) {
    margin-right: .5rem;
}
/*list items*/
.list-items{
    display: flex;
    align-items: center;
}
.list-item-icon{
    width: 35px;
    height: 35px;
    margin: 2px 5px;
}
/*list items*/