.auth-page section{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 15px;
}
.auth-card{
    width: 100%;
    font-size: 16px;
    max-width: 500px;
    width: 500px;
    margin: 0 auto;
    border-radius: 10px;
    border: 2px solid var(--primary-color);
}
.auth-card .card-body{
    padding: 20px;
}
.auth-page-title h2{
    font-family: "Dancing Script", cursive;
    font-size:38px;
}
@media (max-width:575px) {
    .auth-card{
        width: 320px;
    }
    .auth-card .card-body{
        padding:15px !important;
    }
    .auth-page section{
        height: auto;
    }
}