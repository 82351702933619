.share-component.main-share{
    position: fixed !important;
}
.share-component.main-share .share-wrapper{
    background-color:white;
    padding:20px;
}
.share-component.main-share .share-wrapper .copy-btn{
    width: 50px;
    height: 50px;
}